import { Link as ChakraLink, LinkProps, useTheme } from '@chakra-ui/react';

import React from 'react';

type Props = LinkProps;

const Link: React.FC<Props> = ({ children, ...props }: Props) => {
  const theme = useTheme();

  return (
    <ChakraLink _focus={{ boxShadow: 'none' }} color={theme.utilityColors.accentColor} {...props}>
      {children}
    </ChakraLink>
  );
};
export default Link;
