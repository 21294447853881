import { ButtonProps, Tab as ChakraTab } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props extends ButtonProps {
  children: ReactNode;
}

const Tab: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <ChakraTab
      fontSize="md"
      fontWeight="600"
      borderColor="gray.100"
      _active={{ backgroundColor: 'gray.50' }}
      _focus={{
        zIndex: 1,
      }}
      _selected={{
        color: 'purple.500',
        borderColor: 'currentColor',
        fontWeight: 600,
      }}
      {...props}
    >
      {children}
    </ChakraTab>
  );
};
export default Tab;
