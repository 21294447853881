import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react';

import React from 'react';

const lineHeightAndLetterSpacingProps = (props) => {
  if (props.fontSize === 'xl') {
    // Heading 2
    return {
      fontWeight: '800',
      lineHeight: '110%',
    };
  } else if (props.fontSize === '2xl') {
    // Heading 1
    return {
      fontWeight: '800',
      lineHeight: '110%',
    };
  } else {
    // Hero
    return {
      fontWeight: '800',
      lineHeight: '120%',
      letterSpacing: '-0.01em',
    };
  }
};

const Heading: React.FC<HeadingProps> = ({ children, ...props }: HeadingProps) => {
  return (
    <ChakraHeading {...lineHeightAndLetterSpacingProps(props)} {...props}>
      {children}
    </ChakraHeading>
  );
};
export default Heading;
